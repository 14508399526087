import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4c845046 = () => interopDefault(import('../pages/2024bbq.vue' /* webpackChunkName: "pages/2024bbq" */))
const _1fe56940 = () => interopDefault(import('../pages/2024bbq-thanks.vue' /* webpackChunkName: "pages/2024bbq-thanks" */))
const _59a69305 = () => interopDefault(import('../pages/aspenridgeHomes.vue' /* webpackChunkName: "pages/aspenridgeHomes" */))
const _7d4482ef = () => interopDefault(import('../pages/bf-ns-thankyou.vue' /* webpackChunkName: "pages/bf-ns-thankyou" */))
const _0705af60 = () => interopDefault(import('../pages/bf-ns-thankyou-test.vue' /* webpackChunkName: "pages/bf-ns-thankyou-test" */))
const _656c2ca8 = () => interopDefault(import('../pages/bf-ppc-lp.vue' /* webpackChunkName: "pages/bf-ppc-lp" */))
const _6878c736 = () => interopDefault(import('../pages/bf-ppc-lp-Hubspot.vue' /* webpackChunkName: "pages/bf-ppc-lp-Hubspot" */))
const _13ba127f = () => interopDefault(import('../pages/bf-ppc-lp-old-staging-version.vue' /* webpackChunkName: "pages/bf-ppc-lp-old-staging-version" */))
const _860e9184 = () => interopDefault(import('../pages/bf-ppc-lp-staging-v1.vue' /* webpackChunkName: "pages/bf-ppc-lp-staging-v1" */))
const _8ee1cb7e = () => interopDefault(import('../pages/bh-brokerportal.vue' /* webpackChunkName: "pages/bh-brokerportal" */))
const _39afdf2a = () => interopDefault(import('../pages/brookfieldHomes.vue' /* webpackChunkName: "pages/brookfieldHomes" */))
const _35ea3ed3 = () => interopDefault(import('../pages/homeowner-fall-bbq.vue' /* webpackChunkName: "pages/homeowner-fall-bbq" */))
const _79e367df = () => interopDefault(import('../pages/homeowner-fall-bbq-thankyou.vue' /* webpackChunkName: "pages/homeowner-fall-bbq-thankyou" */))
const _4a6f084a = () => interopDefault(import('../pages/index-Feb1_2023.vue' /* webpackChunkName: "pages/index-Feb1_2023" */))
const _70c0d666 = () => interopDefault(import('../pages/index-jun-29-2020-backup.vue' /* webpackChunkName: "pages/index-jun-29-2020-backup" */))
const _69bcfb01 = () => interopDefault(import('../pages/index-old.vue' /* webpackChunkName: "pages/index-old" */))
const _2e51a034 = () => interopDefault(import('../pages/index-old-2.vue' /* webpackChunkName: "pages/index-old-2" */))
const _5321ff44 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _b3c9f0ca = () => interopDefault(import('../pages/register_CM.vue' /* webpackChunkName: "pages/register_CM" */))
const _a9c5e466 = () => interopDefault(import('../pages/safety.vue' /* webpackChunkName: "pages/safety" */))
const _070ee140 = () => interopDefault(import('../pages/singles.vue' /* webpackChunkName: "pages/singles" */))
const _0892b888 = () => interopDefault(import('../pages/sitetable.vue' /* webpackChunkName: "pages/sitetable" */))
const _aa6334a0 = () => interopDefault(import('../pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _de8c859e = () => interopDefault(import('../pages/thankyou-bf.vue' /* webpackChunkName: "pages/thankyou-bf" */))
const _b429d39c = () => interopDefault(import('../pages/thankyou-ns.vue' /* webpackChunkName: "pages/thankyou-ns" */))
const _5276a676 = () => interopDefault(import('../pages/towerhillHomes.vue' /* webpackChunkName: "pages/towerhillHomes" */))
const _6fc399be = () => interopDefault(import('../pages/blogs/a-new-fitter-healthier-you.vue' /* webpackChunkName: "pages/blogs/a-new-fitter-healthier-you" */))
const _25b40ce0 = () => interopDefault(import('../pages/blogs/activities-at-petticoat.vue' /* webpackChunkName: "pages/blogs/activities-at-petticoat" */))
const _16afa3f8 = () => interopDefault(import('../pages/blogs/adorable-antique-shops-in-the-pickering-area.vue' /* webpackChunkName: "pages/blogs/adorable-antique-shops-in-the-pickering-area" */))
const _0e692efd = () => interopDefault(import('../pages/blogs/college-close-to-home.vue' /* webpackChunkName: "pages/blogs/college-close-to-home" */))
const _2ddccb08 = () => interopDefault(import('../pages/blogs/enjoy-life-on-the-water-near-new-seaton.vue' /* webpackChunkName: "pages/blogs/enjoy-life-on-the-water-near-new-seaton" */))
const _65d420e0 = () => interopDefault(import('../pages/blogs/event-listing-artfest-on-the-esplanade.vue' /* webpackChunkName: "pages/blogs/event-listing-artfest-on-the-esplanade" */))
const _0adab216 = () => interopDefault(import('../pages/blogs/five-must-play-golf-courses-in-pickering.vue' /* webpackChunkName: "pages/blogs/five-must-play-golf-courses-in-pickering" */))
const _6a6ffced = () => interopDefault(import('../pages/blogs/getting-around-at-new-seaton.vue' /* webpackChunkName: "pages/blogs/getting-around-at-new-seaton" */))
const _3d6c11ed = () => interopDefault(import('../pages/blogs/great-grown-up-birthday-party-ideas-in-pickering.vue' /* webpackChunkName: "pages/blogs/great-grown-up-birthday-party-ideas-in-pickering" */))
const _241101bd = () => interopDefault(import('../pages/blogs/higher-living-meets-high-education.vue' /* webpackChunkName: "pages/blogs/higher-living-meets-high-education" */))
const _69eccea6 = () => interopDefault(import('../pages/blogs/march-break-madness-activities-kids-will-love.vue' /* webpackChunkName: "pages/blogs/march-break-madness-activities-kids-will-love" */))
const _f651bdda = () => interopDefault(import('../pages/blogs/new-approach-to-complete-community-design.vue' /* webpackChunkName: "pages/blogs/new-approach-to-complete-community-design" */))
const _0a71ee88 = () => interopDefault(import('../pages/blogs/new-seaton-featured-in-the-toronto-sun.vue' /* webpackChunkName: "pages/blogs/new-seaton-featured-in-the-toronto-sun" */))
const _c61321ea = () => interopDefault(import('../pages/blogs/new-ways-to-have-fun-this-family-day-in-pickering.vue' /* webpackChunkName: "pages/blogs/new-ways-to-have-fun-this-family-day-in-pickering" */))
const _7d758abe = () => interopDefault(import('../pages/blogs/our-5-favourite-things-to-do-at-rouge-national-urban-park.vue' /* webpackChunkName: "pages/blogs/our-5-favourite-things-to-do-at-rouge-national-urban-park" */))
const _d029a5e0 = () => interopDefault(import('../pages/blogs/petapolooza-pickering.vue' /* webpackChunkName: "pages/blogs/petapolooza-pickering" */))
const _cf4e538c = () => interopDefault(import('../pages/blogs/pickering-summer-concert-series.vue' /* webpackChunkName: "pages/blogs/pickering-summer-concert-series" */))
const _9811ae10 = () => interopDefault(import('../pages/blogs/six-spots-you-need-to-try-in-downtown-pickering.vue' /* webpackChunkName: "pages/blogs/six-spots-you-need-to-try-in-downtown-pickering" */))
const _13132a52 = () => interopDefault(import('../pages/blogs/the-best-community-facilities-near-new-seaton.vue' /* webpackChunkName: "pages/blogs/the-best-community-facilities-near-new-seaton" */))
const _036f67ab = () => interopDefault(import('../pages/blogs/the-best-micro-breweries.vue' /* webpackChunkName: "pages/blogs/the-best-micro-breweries" */))
const _18b43f99 = () => interopDefault(import('../pages/blogs/top-shopping.vue' /* webpackChunkName: "pages/blogs/top-shopping" */))
const _101ecda9 = () => interopDefault(import('../pages/blogs/top-vendors-at-pickering-farmers-market.vue' /* webpackChunkName: "pages/blogs/top-vendors-at-pickering-farmers-market" */))
const _3a9ea270 = () => interopDefault(import('../pages/blogs/whats-new-at-the-zoo.vue' /* webpackChunkName: "pages/blogs/whats-new-at-the-zoo" */))
const _e50f9b8a = () => interopDefault(import('../pages/highlight/award-winning-builders.vue' /* webpackChunkName: "pages/highlight/award-winning-builders" */))
const _5c46417c = () => interopDefault(import('../pages/highlight/community-vision.vue' /* webpackChunkName: "pages/highlight/community-vision" */))
const _00c13264 = () => interopDefault(import('../pages/highlight/new-home-types.vue' /* webpackChunkName: "pages/highlight/new-home-types" */))
const _128e2d56 = () => interopDefault(import('../pages/highlight/new-parks.vue' /* webpackChunkName: "pages/highlight/new-parks" */))
const _f13a1e14 = () => interopDefault(import('../pages/highlight/new-retail-shops.vue' /* webpackChunkName: "pages/highlight/new-retail-shops" */))
const _8c8a5a68 = () => interopDefault(import('../pages/highlight/new-schools.vue' /* webpackChunkName: "pages/highlight/new-schools" */))
const _de3c3720 = () => interopDefault(import('../pages/highlight/new-trails.vue' /* webpackChunkName: "pages/highlight/new-trails" */))
const _109529c0 = () => interopDefault(import('../pages/highlight/new-transit.vue' /* webpackChunkName: "pages/highlight/new-transit" */))
const _61e574a7 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/2024bbq",
    component: _4c845046,
    name: "2024bbq"
  }, {
    path: "/2024bbq-thanks",
    component: _1fe56940,
    name: "2024bbq-thanks"
  }, {
    path: "/aspenridgeHomes",
    component: _59a69305,
    name: "aspenridgeHomes"
  }, {
    path: "/bf-ns-thankyou",
    component: _7d4482ef,
    name: "bf-ns-thankyou"
  }, {
    path: "/bf-ns-thankyou-test",
    component: _0705af60,
    name: "bf-ns-thankyou-test"
  }, {
    path: "/bf-ppc-lp",
    component: _656c2ca8,
    name: "bf-ppc-lp"
  }, {
    path: "/bf-ppc-lp-Hubspot",
    component: _6878c736,
    name: "bf-ppc-lp-Hubspot"
  }, {
    path: "/bf-ppc-lp-old-staging-version",
    component: _13ba127f,
    name: "bf-ppc-lp-old-staging-version"
  }, {
    path: "/bf-ppc-lp-staging-v1",
    component: _860e9184,
    name: "bf-ppc-lp-staging-v1"
  }, {
    path: "/bh-brokerportal",
    component: _8ee1cb7e,
    name: "bh-brokerportal"
  }, {
    path: "/brookfieldHomes",
    component: _39afdf2a,
    name: "brookfieldHomes"
  }, {
    path: "/homeowner-fall-bbq",
    component: _35ea3ed3,
    name: "homeowner-fall-bbq"
  }, {
    path: "/homeowner-fall-bbq-thankyou",
    component: _79e367df,
    name: "homeowner-fall-bbq-thankyou"
  }, {
    path: "/index-Feb1_2023",
    component: _4a6f084a,
    name: "index-Feb1_2023"
  }, {
    path: "/index-jun-29-2020-backup",
    component: _70c0d666,
    name: "index-jun-29-2020-backup"
  }, {
    path: "/index-old",
    component: _69bcfb01,
    name: "index-old"
  }, {
    path: "/index-old-2",
    component: _2e51a034,
    name: "index-old-2"
  }, {
    path: "/register",
    component: _5321ff44,
    name: "register"
  }, {
    path: "/register_CM",
    component: _b3c9f0ca,
    name: "register_CM"
  }, {
    path: "/safety",
    component: _a9c5e466,
    name: "safety"
  }, {
    path: "/singles",
    component: _070ee140,
    name: "singles"
  }, {
    path: "/sitetable",
    component: _0892b888,
    name: "sitetable"
  }, {
    path: "/thankyou",
    component: _aa6334a0,
    name: "thankyou"
  }, {
    path: "/thankyou-bf",
    component: _de8c859e,
    name: "thankyou-bf"
  }, {
    path: "/thankyou-ns",
    component: _b429d39c,
    name: "thankyou-ns"
  }, {
    path: "/towerhillHomes",
    component: _5276a676,
    name: "towerhillHomes"
  }, {
    path: "/blogs/a-new-fitter-healthier-you",
    component: _6fc399be,
    name: "blogs-a-new-fitter-healthier-you"
  }, {
    path: "/blogs/activities-at-petticoat",
    component: _25b40ce0,
    name: "blogs-activities-at-petticoat"
  }, {
    path: "/blogs/adorable-antique-shops-in-the-pickering-area",
    component: _16afa3f8,
    name: "blogs-adorable-antique-shops-in-the-pickering-area"
  }, {
    path: "/blogs/college-close-to-home",
    component: _0e692efd,
    name: "blogs-college-close-to-home"
  }, {
    path: "/blogs/enjoy-life-on-the-water-near-new-seaton",
    component: _2ddccb08,
    name: "blogs-enjoy-life-on-the-water-near-new-seaton"
  }, {
    path: "/blogs/event-listing-artfest-on-the-esplanade",
    component: _65d420e0,
    name: "blogs-event-listing-artfest-on-the-esplanade"
  }, {
    path: "/blogs/five-must-play-golf-courses-in-pickering",
    component: _0adab216,
    name: "blogs-five-must-play-golf-courses-in-pickering"
  }, {
    path: "/blogs/getting-around-at-new-seaton",
    component: _6a6ffced,
    name: "blogs-getting-around-at-new-seaton"
  }, {
    path: "/blogs/great-grown-up-birthday-party-ideas-in-pickering",
    component: _3d6c11ed,
    name: "blogs-great-grown-up-birthday-party-ideas-in-pickering"
  }, {
    path: "/blogs/higher-living-meets-high-education",
    component: _241101bd,
    name: "blogs-higher-living-meets-high-education"
  }, {
    path: "/blogs/march-break-madness-activities-kids-will-love",
    component: _69eccea6,
    name: "blogs-march-break-madness-activities-kids-will-love"
  }, {
    path: "/blogs/new-approach-to-complete-community-design",
    component: _f651bdda,
    name: "blogs-new-approach-to-complete-community-design"
  }, {
    path: "/blogs/new-seaton-featured-in-the-toronto-sun",
    component: _0a71ee88,
    name: "blogs-new-seaton-featured-in-the-toronto-sun"
  }, {
    path: "/blogs/new-ways-to-have-fun-this-family-day-in-pickering",
    component: _c61321ea,
    name: "blogs-new-ways-to-have-fun-this-family-day-in-pickering"
  }, {
    path: "/blogs/our-5-favourite-things-to-do-at-rouge-national-urban-park",
    component: _7d758abe,
    name: "blogs-our-5-favourite-things-to-do-at-rouge-national-urban-park"
  }, {
    path: "/blogs/petapolooza-pickering",
    component: _d029a5e0,
    name: "blogs-petapolooza-pickering"
  }, {
    path: "/blogs/pickering-summer-concert-series",
    component: _cf4e538c,
    name: "blogs-pickering-summer-concert-series"
  }, {
    path: "/blogs/six-spots-you-need-to-try-in-downtown-pickering",
    component: _9811ae10,
    name: "blogs-six-spots-you-need-to-try-in-downtown-pickering"
  }, {
    path: "/blogs/the-best-community-facilities-near-new-seaton",
    component: _13132a52,
    name: "blogs-the-best-community-facilities-near-new-seaton"
  }, {
    path: "/blogs/the-best-micro-breweries",
    component: _036f67ab,
    name: "blogs-the-best-micro-breweries"
  }, {
    path: "/blogs/top-shopping",
    component: _18b43f99,
    name: "blogs-top-shopping"
  }, {
    path: "/blogs/top-vendors-at-pickering-farmers-market",
    component: _101ecda9,
    name: "blogs-top-vendors-at-pickering-farmers-market"
  }, {
    path: "/blogs/whats-new-at-the-zoo",
    component: _3a9ea270,
    name: "blogs-whats-new-at-the-zoo"
  }, {
    path: "/highlight/award-winning-builders",
    component: _e50f9b8a,
    name: "highlight-award-winning-builders"
  }, {
    path: "/highlight/community-vision",
    component: _5c46417c,
    name: "highlight-community-vision"
  }, {
    path: "/highlight/new-home-types",
    component: _00c13264,
    name: "highlight-new-home-types"
  }, {
    path: "/highlight/new-parks",
    component: _128e2d56,
    name: "highlight-new-parks"
  }, {
    path: "/highlight/new-retail-shops",
    component: _f13a1e14,
    name: "highlight-new-retail-shops"
  }, {
    path: "/highlight/new-schools",
    component: _8c8a5a68,
    name: "highlight-new-schools"
  }, {
    path: "/highlight/new-trails",
    component: _de3c3720,
    name: "highlight-new-trails"
  }, {
    path: "/highlight/new-transit",
    component: _109529c0,
    name: "highlight-new-transit"
  }, {
    path: "/",
    component: _61e574a7,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
